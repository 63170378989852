import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    enableSubmit: false
};

// ==============================|| SLICE - NEW CAMPAIGN ||============================== //

const newCampaign = createSlice({
    name: 'newCampaign',
    initialState,
    reducers: {
        unlockSubmit(state, action) {
            state.enableSubmit = action.payload;
        }
    }
});

export default newCampaign.reducer;

export const { unlockSubmit } = newCampaign.actions;
