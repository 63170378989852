import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import PageTitleSection from './PageTitleSection';
import { unlockSubmit } from 'store/slices/newCampaign';
import { useDispatch, useSelector } from 'store';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useEffect } from 'react';

import projectIcon from 'assets/images/project-icon.png';

const WorkplaceHeader = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { enableSubmit } = useSelector((state) => state.newCampaign);
    const navigate = useNavigate();
    const handleBackToWorkPlace = () => {
        dispatch(unlockSubmit(false));
        navigate('workplace');
    };

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    marginRight: '16px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="img" alt="Project logo" src={projectIcon} sx={{ height: '42.25px', marginRight: '24px' }} />
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<ArrowBackIosNew sx={{ fontSize: '16px !important' }} />}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        color: '#7A2897',
                        fontWeight: 700,
                        borderColor: '#7A2897',
                        '&:hover': {
                            backgroundColor: '#FFFFFF',
                            borderColor: '#7A2897',
                            color: '#7A2897'
                        }
                    }}
                    onClick={handleBackToWorkPlace}
                >
                    Exit
                </Button>
            </Box>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <PageTitleSection title="Getting started" />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
                <Button
                    variant="contained"
                    form="new-campaign-form"
                    type="submit"
                    size="large"
                    endIcon={<ArrowForwardIos sx={{ fontSize: '16px !important' }} />}
                    sx={{
                        backgroundColor: '#7A2897',
                        color: '#FFFFFF',
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#7A2897',
                            color: '#FFFFFF'
                        }
                    }}
                    disabled={!enableSubmit}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default WorkplaceHeader;
