import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Box, Link } from '@mui/material';

// project imports
import { WORKPLACE_PATH } from 'config';
import Logo from 'ui-component/Logo';
import projectLogo from 'assets/images/project-logo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={WORKPLACE_PATH}>
        <Box component="img" alt="Nectar Icon" src={projectLogo} sx={{ width: '200px', height: 'auto' }} />
    </Link>
);

export default LogoSection;
