// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconLayoutBoard } from '@tabler/icons';

// constant
const icons = {
    IconLayoutBoard
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const aiTools = {
    id: 'ai-tool',
    title: <FormattedMessage id="ai-tools" />,
    type: 'group',
    children: [
        {
            id: 'campaign',
            title: <FormattedMessage id="campaign" />,
            type: 'item',
            url: '/workplace',
            icon: icons.IconLayoutBoard,
            breadcrumbs: false
        }
    ]
};

export default aiTools;
