import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    error: null,
    campaigns: [],
    count: 0,
    page: 1,
    pageDisplay: 100,
    modeFilter: 'all',
    statusFilter: 'all',
    sorting: 'lastModifiedDate',
    ordering: 'desc'
};

const slice = createSlice({
    name: 'workplace',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getCampaignsSuccess(state, action) {
            state.campaigns = action.payload.campaignList;
            state.count = action.payload.total;
        },
        updateMode(state, action) {
            state.modeFilter = action.payload;
        },
        updateStatus(state, action) {
            state.statusFilter = action.payload;
        },
        updateSorting(state, action) {
            state.sorting = action.payload;
        },
        updateSortingOrder(state, action) {
            state.ordering = action.payload;
        }
    }
});

export default slice.reducer;

export function updateModeFilter(data) {
    return () => {
        dispatch(slice.actions.updateMode(data));
    };
}

export function updateStatusFilter(data) {
    return () => {
        dispatch(slice.actions.updateStatus(data));
    };
}

export function updateSortingField(data) {
    return () => {
        dispatch(slice.actions.updateSorting(data));
    };
}

export function updateOrdering(data) {
    return () => {
        dispatch(slice.actions.updateSortingOrder(data));
    };
}

export function getCampaigns(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/list', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.getCampaignsSuccess(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function closeCampaign(data, callback) {
    return async () => {
        try {
            const response = await axios.post(
                '/api/campaign/update',
                {
                    id: data.campaignId,
                    status: 'completed'
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('idToken')}`
                    }
                }
            );
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
