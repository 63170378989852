import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';

const PageTitleSection = ({ title }) => {
    const theme = useTheme();

    return (
        <>
            <Typography color="#2D3648" fontSize="1.5rem" variant="h1">
                {title}
            </Typography>
        </>
    );
};

PageTitleSection.propTypes = {
    title: PropTypes.string
};

export default PageTitleSection;
