import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { height } from '@mui/system';

const AnchorTag = () => {
    const [isGeneratorInViewport, setIsGeneratorInViewport] = useState(false);
    const [isCampaignInfoInViewport, setIsCampaignInfoInViewport] = useState(false);

    const { type } = useParams();

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        window.addEventListener('scroll', (e) => {
            const generatorDom = document.querySelector('#generator');
            const campaignInfoDom = document.querySelector('#campaign-info');
            if (generatorDom && generatorDom.getBoundingClientRect().top < viewportHeight) {
                setIsGeneratorInViewport(true);
            } else {
                setIsGeneratorInViewport(false);
            }
            if (campaignInfoDom && campaignInfoDom.getBoundingClientRect().top < viewportHeight) {
                setIsCampaignInfoInViewport(true);
            } else {
                setIsCampaignInfoInViewport(false);
            }
        });
    }, []);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'center',
                        marginRight: '16px',
                        bgcolor: '#F9F5FF',
                        borderWidth: '1px 0 1px 1px',
                        borderStyle: 'solid',
                        borderColor: '#B146A0',
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px',
                        width: '150px',
                        height: '42.25px',
                        paddingLeft: '16px',
                        position: 'relative',
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            width: '32px',
                            height: '32px',
                            right: '-16px',
                            bgcolor: '#F9F5FF',
                            borderTop: '1px solid #B146A0',
                            borderRight: '1px solid #B146A0',
                            borderBottom: '0px solid #B146A0',
                            borderLeft: '0px solid #B146A0',
                            borderRadius: '4px',
                            transform: 'rotate(45deg)',
                            zIndex: 1
                        },
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    onClick={() => {
                        const target = document.querySelector('#prompt');
                        const headerOffset = 76.25;
                        const elementPosition = target.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth'
                        });
                    }}
                >
                    <Typography
                        sx={{
                            color: '#7A2897',
                            fontSize: '0.875rem',
                            fontWeight: 500
                        }}
                    >
                        Prompt
                    </Typography>
                    <Typography
                        sx={{
                            color: '#7A2897',
                            fontSize: '0.75rem',
                            fontWeight: 400
                        }}
                    >
                        Short step description
                    </Typography>
                </Box>
                {type === 'image' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center',
                            marginRight: '16px',
                            bgcolor: isCampaignInfoInViewport ? '#F9F5FF' : '#FFFFFF',
                            borderWidth: '1px 0 1px 0',
                            borderStyle: 'solid',
                            borderColor: isCampaignInfoInViewport ? '#B146A0' : '#D2D6DC',
                            width: '163px',
                            height: '42.25px',
                            paddingLeft: '30px',
                            position: 'relative',
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                width: '30px',
                                height: '30px',
                                left: '-15px',
                                bgcolor: '#FFFFFF',
                                borderTop: isCampaignInfoInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                                borderRight: isCampaignInfoInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                                borderBottom: '0px solid #D2D6DC',
                                borderLeft: '0px solid #D2D6DC',
                                borderTopRightRadius: '4px',
                                transform: 'rotate(45deg)'
                            },
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                width: '32px',
                                height: '32px',
                                right: '-16px',
                                bgcolor: isCampaignInfoInViewport ? '#F9F5FF' : '#FFFFFF',
                                borderTop: isCampaignInfoInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                                borderRight: isCampaignInfoInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                                borderBottom: '0px solid #B146A0',
                                borderLeft: '0px solid #B146A0',
                                borderRadius: '4px',
                                transform: 'rotate(45deg)',
                                zIndex: 1
                            },
                            '&:hover': {
                                cursor: document.querySelector('#campaign-info') ? 'pointer' : 'default'
                            }
                        }}
                        onClick={() => {
                            const target = document.querySelector('#campaign-info');
                            if (target) {
                                const headerOffset = 76.25;
                                const elementPosition = target.getBoundingClientRect().top;
                                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: 'smooth'
                                });
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: isCampaignInfoInViewport ? '#7A2897' : '#3C4257',
                                fontSize: '0.875rem',
                                fontWeight: 500
                            }}
                        >
                            Campaign Info
                        </Typography>
                        <Typography
                            sx={{
                                color: isCampaignInfoInViewport ? '#7A2897' : '#697386',
                                fontSize: '0.75rem',
                                fontWeight: 400
                            }}
                        >
                            Short step description
                        </Typography>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'center',
                        bgcolor: isGeneratorInViewport ? '#F9F5FF' : '#FFFFFF',
                        borderWidth: '1px 1px 1px 0',
                        borderStyle: 'solid',
                        borderColor: isGeneratorInViewport ? '#B146A0' : '#D2D6DC',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        width: '163px',
                        height: '42.25px',
                        paddingLeft: '30px',
                        position: 'relative',
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            width: '30px',
                            height: '30px',
                            left: '-15px',
                            bgcolor: '#FFFFFF',
                            borderTop: isGeneratorInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                            borderRight: isGeneratorInViewport ? '1px solid #B146A0' : '1px solid #D2D6DC',
                            borderBottom: '0px solid #D2D6DC',
                            borderLeft: '0px solid #D2D6DC',
                            borderTopRightRadius: '4px',
                            transform: 'rotate(45deg)'
                        },
                        '&:hover': {
                            cursor: document.querySelector('#generator') ? 'pointer' : 'default'
                        }
                    }}
                    onClick={() => {
                        const target = document.querySelector('#generator');
                        if (target) {
                            const headerOffset = 76.25;
                            const elementPosition = target.getBoundingClientRect().top;
                            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                            window.scrollTo({
                                top: offsetPosition,
                                behavior: 'smooth'
                            });
                        }
                    }}
                >
                    <Typography
                        sx={{
                            color: isGeneratorInViewport ? '#7A2897' : '#3C4257',
                            fontSize: '0.875rem',
                            fontWeight: 500
                        }}
                    >
                        Generator
                    </Typography>
                    <Typography
                        sx={{
                            color: isGeneratorInViewport ? '#7A2897' : '#697386',
                            fontSize: '0.75rem',
                            fontWeight: 400
                        }}
                    >
                        Short step description
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default AnchorTag;
