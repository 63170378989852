import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const WorkplacePage = Loadable(lazy(() => import('views/workplace-page')));
const ReviewPage = Loadable(lazy(() => import('views/review-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/workplace',
            element: <WorkplacePage />
        },
        {
            path: '/review/:type/:id',
            element: <ReviewPage />
        }
    ]
};

export default MainRoutes;
