import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    enableSubmit: false,
    savedResultOpen: false,
    resultGenerated: false,
    resultIsPending: false,
    warningPopupOpen: false,
    alertConfirmation: false,
    tempFormValue: null,
    targetResultTranslate: null,
    tempResultPrompt: null,
    tempProductImagePath: null,
    tempBrandLogoPath: null,
    campaignDetail: null,
    savedResult: null,
    error: null,
    personaList: null,
    imageStyleList: null,
    imageTemplateList: null,
    combinedImage: null
};

// ==============================|| SLICE - NEW CAMPAIGN ||============================== //

const slice = createSlice({
    name: 'generator',
    initialState,
    reducers: {
        resetAllParam(state, action) {
            state.enableSubmit = false;
            state.savedResultOpen = false;
            state.resultGenerated = false;
            state.resultIsPending = false;
            state.warningPopupOpen = false;
            state.alertConfirmation = false;
            state.tempFormValue = null;
            state.targetResultTranslate = null;
            state.tempResultPrompt = null;
            state.tempProductImagePath = null;
            state.tempBrandLogoPath = null;
            state.campaignDetail = null;
            state.savedResult = null;
            state.error = null;
            state.personaList = null;
            state.imageStyleList = null;
            state.imageTemplateList = null;
            state.combinedImage = null;
        },
        hasError(state, action) {
            state.error = { ...action.payload };
        },
        unlockSubmitButton(state, action) {
            state.enableSubmit = action.payload;
        },
        toggleSavedResultModal(state, action) {
            state.savedResultOpen = action.payload;
        },
        updateResultGenerated(state, action) {
            state.resultGenerated = action.payload;
        },
        updateResultIsPending(state, action) {
            state.resultIsPending = action.payload;
        },
        showWarningPopup(state, action) {
            state.warningPopupOpen = action.payload;
        },
        updateAlertStatus(state, action) {
            state.alertConfirmation = action.payload;
        },
        setTempFormValue(state, action) {
            state.tempFormValue = action.payload;
        },
        setLanguageTranslate(state, action) {
            state.targetResultTranslate = action.payload;
        },
        setTempResultPrompt(state, action) {
            state.tempResultPrompt = action.payload;
        },
        setTempProductImagePath(state, action) {
            state.tempProductImagePath = action.payload;
        },
        setTempBrandLogoPath(state, action) {
            state.tempBrandLogoPath = action.payload;
        },
        setCampaignDetail(state, action) {
            state.campaignDetail = action.payload.campaign;
        },
        updateTargetResult(state, action) {
            const existingResult = state.campaignDetail.promotionTextList;
            const updatedResult = [];
            existingResult.forEach((result, index) => {
                if (result.id === action.payload.promotionText.id) {
                    updatedResult.push(action.payload.promotionText);
                } else {
                    updatedResult.push(result);
                }
            });
            state.campaignDetail = {
                ...state.campaignDetail,
                promotionTextList: updatedResult
            };
        },
        updateSavedResult(state, action) {
            state.savedResult = action.payload.promotionTextList;
        },
        setPersonaList(state, action) {
            state.personaList = action.payload;
        },
        setImageStyleList(state, action) {
            state.imageStyleList = action.payload;
        },
        setImageTemplateList(state, action) {
            state.imageTemplateList = action.payload;
        },
        setCombinedImage(state, action) {
            state.combinedImage = action.payload;
        }
    }
});

export default slice.reducer;

export function resetStore(data, callback) {
    return () => {
        dispatch(slice.actions.resetAllParam());
        if (callback) {
            callback();
        }
    };
}

export function unlockSubmit(data) {
    return () => {
        dispatch(slice.actions.unlockSubmitButton(data));
    };
}

export function toggleSavedResult(data) {
    return () => {
        dispatch(slice.actions.toggleSavedResultModal(data));
    };
}

export function changeResultGenerated(data) {
    return () => {
        dispatch(slice.actions.updateResultGenerated(data));
    };
}

export function changeResultIsPending(data) {
    return () => {
        dispatch(slice.actions.updateResultIsPending(data));
    };
}

export function toggleWarningPopup(data) {
    return () => {
        dispatch(slice.actions.showWarningPopup(data));
    };
}

export function updateAlertConfirmation(data, callback) {
    return () => {
        dispatch(slice.actions.updateAlertStatus(data));
        if (callback) {
            callback();
        }
    };
}

export function saveTempFormValue(data) {
    return () => {
        dispatch(slice.actions.setTempFormValue(data));
    };
}

export function saveTargetResultTranslate(data) {
    return () => {
        dispatch(slice.actions.setLanguageTranslate(data));
    };
}

export function saveTempResultPrompt(data) {
    return () => {
        dispatch(slice.actions.setTempResultPrompt(data));
    };
}

export function saveTempProductImage(data) {
    return () => {
        dispatch(slice.actions.setTempProductImagePath(data));
    };
}

export function saveTempBrandLogo(data) {
    return () => {
        dispatch(slice.actions.setTempBrandLogoPath(data));
    };
}

export function changeCombinedImage(data) {
    return () => {
        dispatch(slice.actions.setCombinedImage(data));
    };
}

export function createCampaign(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCampaignDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getTextCampaignDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/text/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCampaignDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function regenerateAllResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/regenerate', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCampaignDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
            dispatch(slice.actions.updateAlertStatus(false));
        }
    };
}

export function regenerateResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/regenerate-text', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.updateTargetResult(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
            dispatch(slice.actions.updateAlertStatus(false));
        }
    };
}

export function translateResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/translate-text', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.updateTargetResult(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
            dispatch(slice.actions.updateAlertStatus(false));
        }
    };
}

export function bookmarkResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/update-text', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function createImageCampaign(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/image/create', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCampaignDetail(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getImageCampaignDetail(data, callback) {
    return async () => {
        try {
            const response = await axios.get(`/api/campaign/image/details/${data}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCampaignDetail(response.data));
            if (response.data.campaign.productImage) {
                dispatch(slice.actions.setTempProductImagePath(response.data.campaign.productImage));
            }
            if (response.data.campaign.brandLogo) {
                dispatch(slice.actions.setTempBrandLogoPath(response.data.campaign.brandLogo));
            }
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getCombineImage(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/image/combine-image', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setCombinedImage(response.data));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function triggerImageRegenerate(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/image/regenerate', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
            dispatch(slice.actions.updateAlertStatus(false));
        }
    };
}

export function updateImageResultStatus(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/image/update-image', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function fileUpload(data, callback) {
    return async () => {
        try {
            const formData = new FormData();
            formData.append('file', data.uploadFile);

            const response = await axios.post('/api/file/upload', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (data.type === 'productImage') {
                dispatch(slice.actions.setTempProductImagePath(response.data));
            }
            if (data.type === 'brandLogo') {
                dispatch(slice.actions.setTempBrandLogoPath(response.data));
            }
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getGeneratorProperites(data, callback) {
    return async () => {
        try {
            const response = await axios.post(
                '/api/properties/list',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('idToken')}`
                    }
                }
            );
            dispatch(slice.actions.setPersonaList(response.data.personaKeywords));
            dispatch(slice.actions.setImageStyleList(response.data.styleList));
            dispatch(slice.actions.setImageTemplateList(response.data.imageTemplateMap));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
