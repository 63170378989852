import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import AnchorTag from './AnchorTag';
import { resetStore } from 'store/slices/generator';
import { resetSavedResultStore } from 'store/slices/savedResult';
import { useDispatch, useSelector } from 'store';

import { ArrowBackIosNew, Refresh } from '@mui/icons-material';
import { useEffect } from 'react';

import projectIcon from 'assets/images/project-icon.png';

const WorkplaceHeader = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { enableSubmit, resultGenerated, resultIsPending } = useSelector((state) => state.generator);
    const navigate = useNavigate();
    const handleBackToWorkPlace = () => {
        dispatch(resetSavedResultStore({}));
        dispatch(
            resetStore({}, () => {
                navigate('workplace');
            })
        );
    };

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <Box component="img" alt="Project logo" src={projectIcon} sx={{ height: '42.25px', marginRight: '24px' }} />
                    <Button
                        variant="outlined"
                        size="large"
                        startIcon={<ArrowBackIosNew sx={{ fontSize: '16px !important' }} />}
                        sx={{
                            backgroundColor: '#FFFFFF',
                            color: '#7A2897',
                            fontWeight: 700,
                            borderColor: '#7A2897',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                                borderColor: '#7A2897',
                                color: '#7A2897'
                            }
                        }}
                        onClick={handleBackToWorkPlace}
                    >
                        Exit
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex'
                    }}
                >
                    <AnchorTag />
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        form="generator-form"
                        type="submit"
                        size="large"
                        endIcon={resultGenerated && !resultIsPending ? <Refresh sx={{ fontSize: '16px !important' }} /> : ''}
                        sx={{
                            backgroundColor: '#7A2897',
                            color: '#FFFFFF',
                            fontWeight: 700,
                            '&:hover': {
                                backgroundColor: '#7A2897',
                                color: '#FFFFFF'
                            }
                        }}
                        disabled={!enableSubmit || resultIsPending}
                    >
                        {resultIsPending ? 'Pending' : 'Generate'}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default WorkplaceHeader;
