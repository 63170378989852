import aiTools from './aiTools';
import aiToolsTwo from './aiTools2';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [aiTools, aiToolsTwo]
};

export default menuItems;
