import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconLogout, IconPhone } from '@tabler/icons';

// project imports
import useAuth from 'hooks/useAuth';

// constant
const icons = {
    IconLogout,
    IconPhone
};

// ==============================|| USER ACTION LIST ||============================== //

const UserAction = () => {
    const { logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const LogoutIcon = icons.IconLogout;
    const ContactIcon = icons.IconPhone;

    return (
        <>
            <List>
                <Divider />
                <ListItemButton
                    sx={{
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: 'inherit',
                        py: 1.25,
                        pl: '24px'
                    }}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
                        <ContactIcon stroke={1.5} size="20px" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h5" fontSize="0.875rem" color="#2D3648">
                                Contact Us
                            </Typography>
                        }
                    />
                </ListItemButton>
                <ListItemButton
                    sx={{
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: 'inherit',
                        py: 1.25,
                        pl: '24px'
                    }}
                    onClick={handleLogout}
                >
                    <ListItemIcon sx={{ my: 'auto', minWidth: 36 }}>
                        <LogoutIcon stroke={1.5} size="20px" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="h5" fontSize="0.875rem" color="#2D3648">
                                Logout
                            </Typography>
                        }
                    />
                </ListItemButton>
            </List>
        </>
    );
};

export default UserAction;
