import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import LogoSection from '../LogoSection';
import PageTitleSection from './PageTitleSection';
import useAuth from 'hooks/useAuth';

const WorkplaceHeader = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();

    const pageTitle = `${user?.name.split(' ')[0]}'s workplace`;

    const handleSwitchToNewCampaign = () => {
        navigate('new-campaign');
    };

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    marginRight: '16px',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <PageTitleSection title={pageTitle} />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box>
                <Button
                    variant="contained"
                    size="large"
                    sx={{
                        backgroundColor: '#7A2897',
                        color: '#FFFFFF',
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#7A2897',
                            color: '#FFFFFF'
                        }
                    }}
                    onClick={handleSwitchToNewCampaign}
                >
                    New Campaign
                </Button>
            </Box>
        </>
    );
};

export default WorkplaceHeader;
