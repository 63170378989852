import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import NewCampaignRoutes from './NewCampaignRoutes';
import GeneratorRoutes from './GeneratorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([LoginRoutes, MainRoutes, NewCampaignRoutes, GeneratorRoutes]);
}
