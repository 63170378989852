import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import GeneratorLayout from 'layout/GeneratorLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const GeneratorPage = Loadable(lazy(() => import('views/generator-page')));

// ==============================|| MAIN ROUTING ||============================== //

const NewCampaignRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <GeneratorLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/generator/:type/:id',
            element: <GeneratorPage />
        }
    ]
};

export default NewCampaignRoutes;
