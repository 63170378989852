import { createSlice } from '@reduxjs/toolkit';

import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    personaFilter: [],
    channelFilter: [],
    styleFilter: [],
    ratioFilter: [],
    sortingField: 'lastModifiedDate',
    ordering: 'desc',
    languageFilter: 'zh',
    targetCampaignSavedResult: [],
    error: null,
    loading: false
};

const slice = createSlice({
    name: 'savedResult',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setSavedResult(state, action) {
            state.targetCampaignSavedResult = action.payload;
        },
        updatePersonaFilter(state, action) {
            state.personaFilter = [...action.payload];
        },
        updateChannelFilter(state, action) {
            state.channelFilter = [...action.payload];
        },
        updateStyleFilter(state, action) {
            state.styleFilter = [...action.payload];
        },
        updateRatioFilter(state, action) {
            state.ratioFilter = [...action.payload];
        },
        updateSortingField(state, action) {
            state.sortingField = action.payload;
        },
        updateOrdering(state, action) {
            state.ordering = action.payload;
        },
        updatelanguageFilter(state, action) {
            state.languageFilter = action.payload;
        },
        resetAllParam(state, action) {
            state.personaFilter = [];
            state.channelFilter = [];
            state.styleFilter = [];
            state.ratioFilter = [];
            state.sortingField = 'lastModifiedDate';
            state.ordering = 'desc';
            state.languageFilter = 'zh';
            state.targetCampaignSavedResult = [];
            state.error = null;
            state.loading = false;
        },
        updateLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export default slice.reducer;

export function resetSavedResultStore(data, callback) {
    return () => {
        dispatch(slice.actions.resetAllParam());
        if (callback) {
            callback();
        }
    };
}

export function setPersonaFilter(data) {
    return () => {
        dispatch(slice.actions.updatePersonaFilter(data));
    };
}

export function setChannelFilter(data) {
    return () => {
        dispatch(slice.actions.updateChannelFilter(data));
    };
}

export function setStyleFilter(data) {
    return () => {
        dispatch(slice.actions.updateStyleFilter(data));
    };
}

export function setRatioFilter(data) {
    return () => {
        dispatch(slice.actions.updateRatioFilter(data));
    };
}

export function setSortingField(data) {
    return () => {
        dispatch(slice.actions.updateSortingField(data));
    };
}

export function setSortingOrder(data) {
    return () => {
        dispatch(slice.actions.updateOrdering(data));
    };
}

export function setLanguageFilter(data) {
    return () => {
        dispatch(slice.actions.updatelanguageFilter(data));
    };
}

export function setResultListLoading(data) {
    return () => {
        dispatch(slice.actions.updateLoading(data));
    };
}

export function getSavedTextResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/text/list-saved', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setSavedResult(response.data.promotionTextMap));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}

export function getSavedImageResult(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/campaign/image/list-saved', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('idToken')}`
                }
            });
            dispatch(slice.actions.setSavedResult(response.data.promotionImageList));
            if (callback) {
                callback();
            }
        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    };
}
