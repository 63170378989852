// material-ui
import { CircularProgress } from '@mui/material';
import MuiBackdrop from '@mui/material/Backdrop';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'store';

// ==============================|| BACKDROP ||============================== //

const Backdrop = () => {
    const theme = useTheme();
    const backdrop = useSelector((state) => state.backdrop);
    const { open } = backdrop;

    return (
        <MuiBackdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="inherit" />
        </MuiBackdrop>
    );
};

export default Backdrop;
