// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconJumpRope } from '@tabler/icons';

// constant
const icons = {
    IconJumpRope
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const aiTools = {
    id: 'ai-tool',
    title: <FormattedMessage id="ai-tools" />,
    type: 'group',
    children: [
        {
            id: 'workflow',
            title: <FormattedMessage id="workflow" />,
            type: 'item',
            url: '/workflow',
            icon: icons.IconJumpRope,
            breadcrumbs: false,
            disabled: true
        }
    ]
};

export default aiTools;
